import React from "react";
import * as promotionsService from "../api/promotionsService";
import { getImages } from "../api/imagesService"
import * as _ from 'lodash';
import PromotionCard from "../components/PromotionCard";

export default function Promotions() {

  const [listPromotions, setListPromotions] = React.useState([]);

  React.useEffect(() => {
    getPromotions();
  }, []);

  const getPromotions = async () => {
    let res;
    if (_.isNil(localStorage.getItem("token"))) {
      res = await promotionsService.getPromotionsWithoutLogin()

      for (let index = 0; index < res.length; index++) {

        const response = await getImages(res[index].image)
        const myBlob = new Blob([response], { type: "image/jpg" });
        const myBlobURL = URL.createObjectURL(myBlob);

        res[index].image = myBlobURL;
      }

    } else {
      res = await promotionsService.getPromotionsMember();
    }

    if (!_.isNil(res)) {
      setListPromotions(res)
    }
  };

  return (
    <PromotionCard text="โปรโมชั่น" promotions={listPromotions} />
  );
}