import { HttpInterceptor } from '../utils/httpClient'

export async function getMemberAffiliate() {
  return await HttpInterceptor.get('/players/v1/affiliate')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
