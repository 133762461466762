import { useSupport } from "../../hook/SupportProvider";
import { styled } from "styled-components";

export default function ContactSupport() {
  const { lineSupport } = useSupport();
  return (
    <div className="mt-4">
      <div className="text-center text-white text-xs">พบปัญหา</div>
      <Text>
        <a
          href={lineSupport.line_url}
          target="_blank"
          className="text-center text-xs underline"
          rel="noreferrer">
          ติดต่อฝ่ายบริการลูกค้า
        </a>
      </Text>
    </div>
  );
}

const Text = styled.div.attrs({})(
  ({ theme }) => `
  color: ${theme.colors.primary};
  `
);