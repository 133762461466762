import { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import {
  TableContainer,
  Theader,
  TheadLabel,
  TBody,
  TR,
  TbodyLabel,
} from './Table'
import {
  getHistoryList,
  getHistoryByDate,
  getHistoryByGame,
} from '../../api/historyService'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import MoonLoader from 'react-spinners/MoonLoader'
import _ from 'lodash'

export default function HistoryTable() {
  const [list, setList] = useState(null)
  const [dateSelect, setDateSelect] = useState()
  const [tstate, setTState] = useState('list')
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    getList()
  }, [])

  const getList = async () => {
    setIsLoad(true)
    setList(null)
    setTState('list')
    const res = await getHistoryList()
    _.isUndefined(res.code) ? setList(res) : setList(null)
    setIsLoad(false)
  }

  const getByDate = async (date) => {
    setIsLoad(true)
    setList(null)
    setTState('date')
    setDateSelect(date)
    const res = await getHistoryByDate(date)
    _.isUndefined(res.code) ? setList(res) : setList(null)
    setIsLoad(false)
  }

  const getByGame = async (code) => {
    setIsLoad(true)
    setList(null)
    setTState('game')
    const res = await getHistoryByGame(dateSelect, code)
    _.isUndefined(res.code) ? setList(res) : setList(null)
    setIsLoad(false)
  }

  return (
    <TableContainer className="w-full overflow-x-auto">
      <Theader>
        {tstate === 'list' ? (
          <TheadLabel>#</TheadLabel>
        ) : tstate === 'date' ? (
          <TheadLabel onClick={() => getList()}>
            <IconBack className="m-auto">
              <HiOutlineChevronLeft />
            </IconBack>
          </TheadLabel>
        ) : (
          <TheadLabel onClick={() => getByDate(dateSelect)}>
            <IconBack className="m-auto">
              <HiOutlineChevronLeft />
            </IconBack>
          </TheadLabel>
        )}
        <TheadLabel className="whitespace-nowrap">
          {tstate === 'list' ? 'วันที่' : 'เกม'}
        </TheadLabel>
        <TheadLabel className="whitespace-nowrap">แทง</TheadLabel>
        <TheadLabel className="whitespace-nowrap">คอมมิชชั่น</TheadLabel>
        <TheadLabel className="whitespace-nowrap">ได้/เสีย</TheadLabel>
        <TheadLabel className="whitespace-nowrap">รวม</TheadLabel>
      </Theader>
      <TBody>
        {!_.isEmpty(list) ? (
          list.map((obj, index) => {
            return (
              <TR key={index}>
                <TbodyLabel>{index + 1}</TbodyLabel>
                {tstate === 'list' ? (
                  <TbodyLabel
                    onClick={() => getByDate(obj.date)}
                    className="underline cursor-pointer"
                  >
                    {obj.date}
                  </TbodyLabel>
                ) : tstate === 'date' ? (
                  <TbodyLabel
                    onClick={() => getByGame(obj.game)}
                    className="underline cursor-pointer"
                  >
                    {obj.game}
                  </TbodyLabel>
                ) : (
                  <TbodyLabel>{obj.game_type}</TbodyLabel>
                )}
                <TbodyLabel>{obj.bets}</TbodyLabel>
                <TbodyLabel>{obj.commission}</TbodyLabel>
                <TbodyLabel>{obj.ggr}</TbodyLabel>
                <TbodyLabel>{obj.commission + obj.ggr}</TbodyLabel>
              </TR>
            )
          })
        ) : isLoad ? (
          <div className="flex justify-center p-4">
            <MoonLoader color={'#fff'} size={18} />
          </div>
        ) : (
          <div className="p-4">
            <span className="text-white">ยังไม่มีประวัติ</span>
          </div>
        )}
      </TBody>
    </TableContainer>
  )
}

const IconBack = styled.div(
  ({ theme }) => `
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};
  border: none;
  padding: 2px 0 4px;
  border-radius: 0.5rem;
  width: 50%;
  cursor: pointer;

  &:hover  {
    background: ${theme.colors.dark[200]};
  }
`
)
