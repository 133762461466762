import axios from "axios";
import { refreshToken } from "../api/authService";

axios.defaults.baseURL = process.env.REACT_APP_PROVIDER_URL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (config.url === "/players/v1/deposit") {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["api-key"] = process.env.REACT_APP_API_KEY;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        if (
          error.response.data.code === 401 &&
          error.response.data.status === "FAILED"
        ) {
          const rf = localStorage.getItem("rf");
          const response = await refreshToken(rf);
          const { token } = response.data;
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        }
      } catch (error) {
        // Handle refresh token error or redirect to login
        alert("Can't refresh token");
      }
    } else if (error.response.status === 404 || error.response.status === 409) {
      return error.response;
    }

    return Promise.reject(error);
  }
);

export const HttpInterceptor = axios;
