import { styled } from "styled-components";

export default function ContactLineFloat({ line }) {
  return (
    <ContactLine href={line.line_url} target="_blank">
      <ImageLine src="/asset/images/icon-line.png" alt="Contact Line" className="h-14 md:h-28 md:hidden" />
    </ContactLine>
  );
}

export const ContactLine = styled.a`
  z-index: 1000;
  position: fixed;
  bottom: 95px;
  right: 0.5rem;
`;

export const ImageLine = styled.img`
  width: auto;
`;
