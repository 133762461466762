import React from "react";
import { useParams } from "react-router-dom"
import Section from "../components/Section";
import { getSlotGames } from "../api/gameService";

export default function SlotGames() {
    let { code } = useParams();
    const [list, setList] = React.useState([]);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await getSlotGames(code);
    setList(res);
  };

  return <Section text={code} games={list} type={'slotGames'} />;
}
