import { styled } from "styled-components";
import { useState, useEffect } from "react";
import { BtnPrimarySm2 } from "./Button";
import * as _ from 'lodash';
import MoonLoader from "react-spinners/MoonLoader";
import Swal from 'sweetalert2'
import clsx from 'clsx'

export default function BankAccount(props) {
  const [act, setAct] = useState(props.account);

  useEffect(() => {
    setAct(props.account)
  }, [props.account]);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value)
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#05C973" fill="#05C973" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" /><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /></svg><span className="text-sm">Copied!</span>`,
      showConfirmButton: false,
      timer: 3000,
      width: '150px',
    });
  };

  return (
    <Box className={clsx(props.className)}>
      {!_.isUndefined(props.account) ?
        <>
          <div className='flex'>
            <img src={`/asset/images/banks/${!_.isNil(act) ? act.bank_name : ''}.png`} alt="banklogo" className="w-10 rounded" />
            <div className="flex flex-col text-sm text-left ml-3">
              <span>{!_.isNil(act) ? act.account_name : ''}</span>
              <span>{!_.isNil(act) ? act.account_no : ''}</span>
            </div>
          </div>
          {props.copyButton && <BtnPrimarySm2 onClick={() => copyToClipboard(act.account_no)}>คัดลอก</BtnPrimarySm2>}
        </> : <MoonLoader
          color={'#fff'}
          size={14}
          aria-label="Loading Spinner"
          data-testid="loader"
        />}
    </Box>
  );
}

const Box = styled.div`
  background-color: #353535;
  border-radius: 8px;
  padding: .8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
`;