import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout';
import Landing from './Page/landing';
import Slot from './Page/slot';
import SlotGames from './Page/slotGames';
import Casino from './Page/casino';
import Sport from './Page/sport';
import Lotto from './Page/lotto';
import Promotion from './Page/promotion';
import Spin from './Page/spin';
import Affiliate from './Page/affiliate';
import AuthProvider from './hook/AuthProvider';
import LaunchGame from './Page/launchGame';
import SupportProvider from './hook/SupportProvider';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SupportProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/launch/:id" element={<LaunchGame />} />
              <Route path="/login" element={<Landing />} />
              <Route path="/register" element={<Landing />} />
              <Route path="/slot" element={<Slot />} />
              <Route path="/game/:code" element={<SlotGames />} />
              <Route path="/casino" element={<Casino />} />
              <Route path="/sport" element={<Sport />} />
              <Route path="/lotto" element={<Lotto />} />
              <Route path="/promotion" element={<Promotion />} />
              <Route path="/spin" element={<Spin />} />
              <Route path="/affiliate" element={<Affiliate />} />
            </Routes>
          </Layout>
        </SupportProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
