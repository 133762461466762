import { useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import { Input } from "../components/Form/Form";
import { ButtonSec } from "../components/Button";
import { getMemberAffiliate } from "../api/InviteAffiliateService";
import { useClipboard } from "use-clipboard-copy";
import { loggedIn } from "../api/authService";
import { useAuth } from "../hook/AuthProvider";
import { sendRockingMoney } from "../api/rockingMoney";
import { HiOutlineRefresh } from "react-icons/hi";
import AffiliateCommissionTypeTable from "../components/Teble/AffiliateCommissionTypeTable";
import AffiliateTransactionTable from "../components/Teble/AffiliateTransactionTable";
import AffiliateDownlineTable from "../components/Teble/AffiliateDownlineTable";
import AffiliatePlayCommissionTable from "../components/Teble/AffiliatePlayCommissionTable";
import QRCode from "react-qr-code";
import MoonLoader from "react-spinners/MoonLoader";
import * as _ from "lodash";

export default function Affiliate() {
  const { user } = useAuth();
  const clipCode = useClipboard({ copiedTimeout: 500 });
  const clipInvite = useClipboard({ copiedTimeout: 500 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errormessage, setErrorMsg] = useState();

  const [playerMember, setPlayerMember] = useState({
    balance: 0,
    code: "",
    commission: [],
    down_line: [],
    transactions: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      InitialAffiliate();
    }
  }, [user]);

  const referralLink = useMemo(() => {
    return `${process.env.REACT_APP_CONTACT_LINE}/register/${playerMember?.code}`;
  }, [playerMember.code]);

  const InitialAffiliate = async () => {
    try {
      setLoading(true);
      const res = await getMemberAffiliate();
      setPlayerMember(res);
    } finally {
      setLoading(false);
    }
  };

  const handleRockingMoney = async (balance) => {
    setErrorMsg("");
    setSuccessMsg("");

    try {
      if (balance < 300) {
        setErrorMsg("ยอดโยกเงินขั้นต่ำ 300 บาท");
      } else {
        const res = await sendRockingMoney();

        if (res.status === 200) {
          setSuccessMsg("โยกเงินสำเร็จ");
          handleRecheckBalance();
        }
      }
    } catch (error) {
      setErrorMsg(error);
    } finally {
      InitialAffiliate();
    }
  };

  const getAmount = async () => {
    const res = await getMemberAffiliate();
    setPlayerMember(res);
    setIsLoaded(false);
  };

  const handleRecheckBalance = async () => {
    setIsLoaded(true);
    getAmount();
  };

  return (
    <TextBox>
      <h2 className="text-center text-2xl">หารายได้</h2>
      <p className="my-3">
        สมาชิกทุกท่าน สามารถหารายได้ง่าย ๆ จากการทำกิจกรรม “แนะนำเพื่อน”
        เพียงแชร์ลิงค์ของตัวเองให้กับเพื่อน ๆ ได้เข้ามาร่วมสมัครสมาชิกกับเรา
        ผู้แนะนำจะมีสิทธิ์ได้รับ “ค่าคอมมิชชั่น”
        ในกรณีเพื่อนที่ท่านแนะนำได้ร่วมเดิมพัน หรือเล่นเกมต่าง ๆ
        ระบบจะดำเนินการจ่ายค่าคอมมิชชั่นตามเปอร์เซ็นที่ได้กำหนดไว้
      </p>
      {loggedIn() && (
        <>
          <p className="mb-3">เงื่อนไขสำหรับพันธมิตรชวนเพื่อน หารายได้ฟรี !!</p>
          <ol className="list-decimal ms-10 my-4">
            <li>ใช้ลิงก์ด้านบนในการชวนเพื่อนสมัครสมาชิก</li>
            <li>
              ท่านสมาชิกจะได้รับคอมมิชชั่นจากสมาชิกที่เพื่อนของท่านชวนต่ออีก 1
              ขั้น (ดาวน์ไลน์)
            </li>
            <li>
              ท่านสมาชิกจะได้รับค่าคอมมิชชั่นจากยอดเล่น
              (แต่ละชนิดจะได้รับคอมมิชชั่นไม่เท่ากัน)
            </li>
            <li>
              คอมมิชชั่นที่ได้รับจะสามารถโยกเป็นกระเป๋าเงินและถอนเงินได้โดยขั้นต่ำในการโยกคือ
              300 บาท
            </li>
          </ol>
          <ContentPanel>
            <div className="grid">
              <ContentBox>
                <h6 className="mb-5 text-white text-center font-bold ">
                  กระเป๋ารายได้
                </h6>
                <BalanceBox>
                  <div className="w-4 h-4"></div>
                  <span className="text-sm text-wrap">
                    {playerMember.balance}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={handleRecheckBalance}>
                    {isLoaded ? (
                      <MoonLoader
                        color={"#fff"}
                        size={14}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <HiOutlineRefresh color="#fff" size={16} />
                    )}
                  </div>
                </BalanceBox>
                {!_.isNil(errormessage) && (
                  <h6 className="mt-2 text-right ml-2" style={{ color: "red" }}>
                    {errormessage}
                  </h6>
                )}
                {!_.isNil(successMsg) && (
                  <h6
                    className="mt-2 text-right ml-2"
                    style={{ color: "white" }}>
                    {successMsg}
                  </h6>
                )}
                <ButtonSec
                  shadow="true"
                  className="my-4 text-lg"
                  onClick={() => handleRockingMoney(playerMember.balance)}>
                  โยกเงิน
                </ButtonSec>
                <br />
                {/*  */}
                <BlockInputCopy>
                  <h6 className="min-w-20 mr-3 mb-1">กระเป๋ารายได้</h6>
                  <div className="flex items-center w-full">
                    <Input
                      ref={clipCode.target}
                      value={playerMember.code}
                      type={""}
                    />
                    <div className="ml-3">
                      <img
                        src={
                          clipCode.copied
                            ? "/asset/images/icon-check.png"
                            : "/asset/images/icon-copy.png"
                        }
                        onClick={clipCode.copy}
                        className="text-lg w-4"
                        alt=""
                      />
                    </div>
                  </div>
                </BlockInputCopy>
                <BlockInputCopy>
                  <h6 className="min-w-20 mr-3 mb-1">ลิงค์บอกต่อ</h6>
                  <div className="flex items-center w-full">
                    <Input
                      ref={clipInvite.target}
                      value={referralLink}
                      type={""}
                    />
                    <div className="ml-3">
                      <img
                        src={
                          clipInvite.copied
                            ? "/asset/images/icon-check.png"
                            : "/asset/images/icon-copy.png"
                        }
                        onClick={clipInvite.copy}
                        className="text-lg w-4"
                        alt=""
                      />
                    </div>
                  </div>
                </BlockInputCopy>
              </ContentBox>
              <div className="m-auto p-4">
                <ContentBox>
                  <div className="text-center bg-white p-6 rounded-lg w-44 h-44">
                    <QRCode value={referralLink} size={128} />
                  </div>
                </ContentBox>
              </div>
            </div>
          </ContentPanel>

          <ContentPanel>
            <ContentBox>
              <div className="text-center">
                <h3 className="text-lg">คอมมิชชั่นการเล่น</h3>
                <AffiliateCommissionTypeTable />
              </div>
            </ContentBox>
          </ContentPanel>
          <ContentPanel>
            <ContentBox>
              <div className="text-center">
                <h3 className="text-lg">ประวัติการทำรายการ</h3>
                <AffiliateTransactionTable
                  transactions={playerMember?.transactions}
                  loading={loading}
                />
              </div>
            </ContentBox>
          </ContentPanel>
          <ContentPanel>
            <ContentBox>
              <div className="text-center">
                <h3 className="text-lg">ดาวน์ไลน์</h3>
                <AffiliateDownlineTable
                  downlines={playerMember?.down_line}
                  loading={loading}
                />
              </div>
            </ContentBox>
          </ContentPanel>
          <ContentPanel>
            <ContentBox>
              <div className="text-center">
                <h3 className="text-lg">คอมมิชชั่นการเล่น</h3>
                <AffiliatePlayCommissionTable />
              </div>
            </ContentBox>
          </ContentPanel>
        </>
      )}
    </TextBox>
  );
}

const TextBox = styled.div.attrs({
  className: "px-4 lg:px-16 xl:px-36 pb-8 text-sm font-light",
})(
  ({ theme }) => `
  color: ${theme.colors.white};
  `
);

const ContentBox = styled.div.attrs({
  className: "mt-5 rounded-md p-4",
})(
  ({ theme }) => `
  background: ${theme.colors.dark[500]};
  box-shadow: var(--box-border-shadow);
`
);
const ContentPanel = styled.div(
  ({ theme }) => `
  margin: auto;
  max-width: 500px;
  border-radius: 0.5rem;
`
);

const BlockInputCopy = styled.div.attrs({
  className: "flex items-center mt-1",
})(
  ({ theme }) => `
  margin-top: 1rem;
  & input{
    font-size: 12px;
  }
  @media screen and (min-width: 621px) {
    h6{
      text-align: end;
    }
  }
  @media screen and (max-width: 620px) {
    flex-wrap: wrap;
  }
`
);
const BalanceBox = styled.div.attrs({
  className: "flex gap-2 items-center font-light h-10 justify-end",
})(
  ({ theme }) => `
  border-radius: 0.5rem;
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};
`
);
