import { styled } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonPrimary } from "./Button";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const menu = [
    {
      name: "หน้าหลัก",
      pathname: "/",
    },
    {
      name: "กีฬา",
      pathname: "/sport",
    },
    {
      name: "คาสิโน",
      pathname: "/casino",
    },
    {
      name: "สล็อต",
      pathname: "/slot",
    },
    {
      name: "วงล้อ",
      pathname: "/spin",
    },
  ];

  return (
    <Box>
      {menu.map((obj, index) => {
        return (
          <ButtonPrimary
            className={`my-1 ${
              location.pathname === obj.pathname ? "active" : ""
            }`}>
            <Menu key={index} onClick={() => navigate(obj.pathname)}>
              <img
                src={`/asset/images/icon-menu/${
                  obj.pathname === "/" ? "home" : obj.pathname
                }.png`}
                alt="icon menu"
                className="w-[65%] m-auto"
              />
              <span
                className="text-xs text-white mb-2 whitespace-nowrap"
                style={{
                  color: location.pathname === obj.pathname ? "black" : "",
                }}>
                {obj.name}
              </span>
              <Active />
            </Menu>
          </ButtonPrimary>
        );
      })}
    </Box>
  );
}

const Box = styled.div.attrs({
  className: "block md:hidden p-2 overflow-scroll",
})(
  ({ theme }) => `
  width: 80px;
  z-index: 2;
  position: fixed;
`
);

const Menu = styled.div.attrs({
  className: "flex flex-col text-center cursor-pointer relative",
})`
  &:hover {
    -webkit-animation: jello-horizontal 0.6s both;
    animation: jello-horizontal 0.6s both;
  }
  &:hover img {
    -webkit-animation: jello-horizontal 0.9s both;
    animation: jello-horizontal 0.9s both;
  }
`;

const Active = styled.div.attrs({
  className: "h-full w-full",
})(
  ({ theme }) => `
  ${ButtonPrimary}.active & {
    color: ${theme.colors.black}
  }
`
);
