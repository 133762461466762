import { useState, useEffect, useRef } from "react";
import {
  ModalContainer,
  ModalContentContainerColor,
  ModalClose,
} from "./Model";
import { HiOutlineX } from "react-icons/hi";
import { styled } from "styled-components";
import { BtnPrimarySm, BtnPrimarySm2 } from "../Button";
import BankAccount from "../BankAccount";
import Table from "../Teble/TableList";
import {
  getDeposit,
  getAgentDeposit,
  uploadSlip,
} from "../../api/depositService";
import _ from "lodash";
import ContactSupport from "../Footer/ContactSupport";

export default function DepositModal(props) {
  const [listDeposit, setListDeposit] = useState([]);
  const [isDisableDeposit, setDisableDeposit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [agentDepositAccount, setAgentDepositAccount] = useState();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    getAgentDepositAct();
  }, []);

  useEffect(() => {
    getListDeposit();
  }, []);

  const getAgentDepositAct = async () => {
    const res = await getAgentDeposit();
    setAgentDepositAccount(res);
  };

  const getListDeposit = async () => {
    const res = await getDeposit();

    if (!_.isNil(res.deposits)) {
      for (let index = 0; index < res.deposits.length; index++) {
        if (_.isEqualWith(res.deposits[index].status, "pending")) {
          setDisableDeposit(true);
        }
      }
      setListDeposit(res.deposits);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSubmitImg = async () => {
    const res = await uploadSlip(selectedImage, agentDepositAccount);

    if ([201, 204].includes(res.status)) {
      setSelectedImage(null);
      await getListDeposit();
    }
  };

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target.id === "modal-container") {
          props.onClose(false);
        }
      }}>
      <ModalContentContainerColor className="text-center">
        <ModalClose onClick={() => props.onClose(false)}>
          <HiOutlineX className="close-icon" size={24} color="#f00" />
        </ModalClose>
        <h2 className="mb-10 text-white text-center font-bold text-xl">ฝาก</h2>

        {props.bankAccount && (
          <BankAccount account={agentDepositAccount} copyButton />
        )}
        <Bx>กรุณาโอนเงินโดยใช้บัญชีที่สมัครเท่านั้น</Bx>
        {props.uploadSlip &&
          !_.isUndefined(agentDepositAccount).is_show_upload_slip && (
            <>
              <Text>อัพโหลดสลิป กรณีเงินไม่เข้าภายใน 3 นาที</Text>
              {selectedImage && (
                <div className="my-3">
                  <div className="w-10 m-auto">
                    <img
                      alt="slip"
                      className="w-full"
                      src={URL.createObjectURL(selectedImage)}
                    />
                  </div>
                  <ButtonGroup>
                    <BtnPrimarySm
                      className="mt-3"
                      onClick={() => setSelectedImage(null)}>
                      <span className="p-3 text-xs">ลบรูป</span>
                    </BtnPrimarySm>
                    <BtnPrimarySm
                      onClick={() => handleSubmitImg()}
                      className="mt-3">
                      <span className="p-3 text-xs">ส่ง</span>
                    </BtnPrimarySm>
                  </ButtonGroup>
                </div>
              )}
              <input
                type="file"
                name="slip"
                className="hidden"
                ref={hiddenFileInput}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
              <BtnPrimarySm2
                shadow="false"
                disabled={isDisableDeposit}
                className="mt-2"
                onClick={handleClick}>
                อัพโหลดสลิปเงินฝาก
              </BtnPrimarySm2>
            </>
          )}
        <Table title={"ฝาก"} list={listDeposit} />
        <hr className="text-white mt-2" />
        <ContactSupport />
      </ModalContentContainerColor>
    </ModalContainer>
  );
}

const Bx = styled.div`
  background-color: #171717;
  border: 1px solid #be2b43;
  border-radius: 8px;
  padding: 0.8rem 0.8rem;
  font-size: 14px;
  font-weight: 300;
  color: #be2b43;
  text-align: center;
  margin-top: 1rem;
`;

const Text = styled.div.attrs({
  className: "text-center text-sm",
})(
  ({ theme }) => `
color: ${theme.colors.white};
margin-top: 1rem;
`
);

const ButtonGroup = styled.div.attrs({
  className: "flex gap-2",
})``;
