import numbro from "numbro";

export function formatDateTime(value) {
  const date = new Date(value);
  const formatted = date.toLocaleString("en-US", {
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formatted;
}

export function formatDate(value) {
  const date = new Date(value);
  const formatted = date.toLocaleString("en-US", {
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formatted;
}

export function formatAccountLength(value) {
  return value.slice(-2);
}

export function moneyFormatWithDecimal(money) {
  return numbro(money).format({ thousandSeparated: true });
}
