import { HttpInterceptor } from "../utils/httpClient";

export async function sendRockingMoney() {
  return await HttpInterceptor.post(`/players/v1/affiliate/transfer`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}
