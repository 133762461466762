import React from "react";
import Section from "../components/Section";
import { getGames } from "../api/gameService";

export default function Lotto() {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await getGames(1);
    setList(res);
  };

  return <Section text="หวยออนไลน์" games={list} />;
}
