import { HttpInterceptor } from '../utils/httpClient'

export async function getPlayerTicketRemaining() {
  return await HttpInterceptor.get('/wallets/v1/player-ticket/remaining')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export async function getConfigurationWheel() {
  return await HttpInterceptor.get('/configuration/v1/client/wheel')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export async function getWheelDraw(body) {
  return await HttpInterceptor.get('/wallets/v1/wheel-draw', {
    params: body || {},
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
