import { HttpInterceptor } from "../utils/httpClient";

export async function getHistoryList() {
    return await HttpInterceptor.get(`/reports/v1/win-loss/player/history`).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}

export async function getHistoryByDate(date) {
    return await HttpInterceptor.get(`/reports/v1/win-loss/player/history?date=${date}`).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}

export async function getHistoryByGame(date, code) {
    return await HttpInterceptor.get(`/reports/v1/win-loss/player/history?date=${date}&game_provider_code=${code}`).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}