import Header from "./components/Header";
import Sidebar from "./components/Sidebar"
import Main from "./main"
import MobileNavbar from "./components/MobileNavbar"
import ContactLineFloat from "./components/ContactLineFloat"
import MobileFooter from "./components/Footer/Mobile";
import { useLocation } from 'react-router-dom';
import { useSupport } from "./hook/SupportProvider";
import { styled } from "styled-components";

const Layout = ({ children }) => {
  const { lineSupport } = useSupport();
  const location = useLocation();

  return location.pathname.split("/")[1] === "launch" ? (
    <>{children}</>
  ) : (
    <LayoutMain>
      <BackgroundMain>
        <Header />
        <MobileNavbar />
        <div className="flex flex-row flex-1 overflow-hidden">
          <Sidebar />
          <Main children={children} />
        </div>
        <ContactLineFloat line={lineSupport} />
        <MobileFooter />
      </BackgroundMain>
    </LayoutMain>
  );
};

const BackgroundMain = styled.div.attrs({
  className: "flex flex-col",
})`
  height: 100%;
  background: url(/asset/images/bg-gold.jpg) center center / cover no-repeat;
`;

const LayoutMain = styled.div.attrs({
  // className: "flex flex-col h-screen",
  className: "h-screen",
})`
  // background: url("/asset/images/bg-gold.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center center;
`;


export default Layout;