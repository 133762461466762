import { decodeToken, isExpired } from 'react-jwt'
import { HttpInterceptor } from '../utils/httpClient'
import Swal from 'sweetalert2'

export async function login(username, password) {
  const body = {
    username: username,
    password: password,
    code: process.env.REACT_APP_AGENT_CODE,
  }
  return await HttpInterceptor.post(`/players/login`, body)
    .then((response) => {
      const res = response.data
      localStorage.setItem('token', res.access_token)
      localStorage.setItem('rf', res.refresh_token)
      localStorage.setItem('session', res.session_state)
    return response.data;
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export async function signup(
  phone,
  password,
  bank_name,
  account_no,
  account_name,
  seller_code
) {
  const body = {
    username: phone,
    password: password,
    tel: phone,
    code: process.env.REACT_APP_AGENT_CODE,
    credit: 0,
    status: 'approve',
    is_validate_bank_account: false,
    bank_accounts: [
      {
        bank_name: bank_name,
        account_no: account_no.toString(),
        account_name: account_name,
        account_type: 'deposit',
        is_active: true,
        prompt_pay_no: '',
      },
    ],
    register_revenue_configuration: [],
    seller_code: seller_code,
  }
  
  return await HttpInterceptor.post(`/players/registerPlayer`, body).then(async response => {
    if (response.status === 201) {
      let res = await login(phone, password);
      return res.status;
    }
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export async function getSellers() {
  return await HttpInterceptor.get(
    `/players/v1/sellers/${process.env.REACT_APP_AGENT_CODE}`
  )
    .then((response) => {
      return response.data.sellerResponses
    })
    .catch((error) => {
      throw error
    })
}

export async function getBanks() {
  return await HttpInterceptor.get(`/wallets/v1/banks/player/${process.env.REACT_APP_AGENT_CODE}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export async function refreshToken(rf) {
  const body = { refresh_token: rf }
  return await HttpInterceptor.post(`/players/refresh`, body)
    .then((response) => {
      const res = response.data
      localStorage.setItem('token', res.access_token)
      localStorage.setItem('rf', res.refresh_token)
      localStorage.setItem('session', res.session_state)

      return response
    })
    .catch((error) => {
      throw error
    })
}

export async function otp(phone) {
  const body = {
    "agent_code": process.env.REACT_APP_AGENT_CODE,
    "player_tel": phone
  }
  return await HttpInterceptor.post(`/players/v1/otp`, body).then(response => {
    return response.data;
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export async function verifyOtp(phone, otp, otpRefCode) {
  
  const body = {
    "agent_code": process.env.REACT_APP_AGENT_CODE,
    "player_tel": phone,
    "otp_code": otp,
    "otp_ref_code": otpRefCode
}

  return await HttpInterceptor.post(`/players/v1/otp/verify`, body).then(response => {
    return response.data;
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export async function resetPassword(phone, otp, otpRefCode, password) {
  const body = {
    "player_tel": phone,
    "otp_code": otp,
    "otp_ref_code": otpRefCode,
    "password": password,
    "agent_code": process.env.REACT_APP_AGENT_CODE,
  }

  return await HttpInterceptor.patch(`/players/v1/reset/password`, body).then(response => {
    return response.data;
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export async function changePassword(password) {
  const body = {
    "password": password,
  }

  return await HttpInterceptor.patch(`/players/v1/change/password`, body).then(response => {
    return response.data;
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 5000,
      width: '400px'
    });
    return error
  });
}

export function loggedIn() {
  return !isExpired(localStorage.getItem('token') || '')
}

export function getDecodeToken() {
  return decodeToken(localStorage.getItem('token') || '')
}
