import React from "react";
import Section from "../components/Section";
import { getGames } from "../api/gameService";

export default function Casino() {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await getGames(2);
    setList(res);
  };
  
  return <Section text="คาสิโนสด" games={list} />;
}
