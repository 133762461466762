import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { styled } from "styled-components";
import { BtnPrimarySm, BtnSecSm } from "./Button";
import { getBalance } from "../api/playerService";
import { useAuth } from "../hook/AuthProvider";
import { HiOutlineRefresh } from "react-icons/hi";
import Deposit from "./Model/Deposit";
import Withdraw from "./Model/Withdraw";
import HistoryModal from "./Model/History";
import ProfileModal from "./Model/Profile";
import MoonLoader from "react-spinners/MoonLoader";
import * as _ from "lodash";
import { moneyFormatWithDecimal } from "../utils/format";
import { useSupport } from "../hook/SupportProvider";

export default function AuthHeader() {
  const { user } = useAuth();
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [balance, setBalance] = useState();
  const { lineSupport } = useSupport();

  /* Concept Polling. Use this field for get balance by Account */
  const timerIdRef = useRef(null);

  useEffect(() => {
    getAmount();
  }, []);

  useEffect(() => {
    if (user) {
      const pollingCallback = async () => {
        /**  Start polling logic here */
        /** Get Balance  */
        let res = await getBalance();

        if (!_.isNil(res)) {
          setBalance(res.Balance);
        }
      };

      const startPolling = () => {
        // Polling balance every 10 seconds and don't stop for now this feature
        timerIdRef.current = setInterval(pollingCallback, 10000);
      };
      startPolling();
    }
  }, [user]);

  const getAmount = async () => {
    const res = await getBalance();
    setBalance(res.Balance);
    setIsLoaded(false);
  };

  const handleRecheckBalance = async () => {
    setIsLoaded(true);
    getAmount();
  };

  return (
    <div>
      <div className="flex gap-2 items-center content-end">
        <a
          href={lineSupport.line_url}
          target="_blank"
          className="text-center text-white text-base font-semibold hidden md:block max-w-36 me-2"
          rel="noreferrer">
          <div
            className="flex flex-row content-center items-center py-1 px-1 w-36"
            style={{ backgroundColor: "#00b901", borderRadius: "0.5rem" }}>
            <img
              src={`/asset/images/icon-line.png`}
              alt="icon menu"
              className="h-6"
            />
            <div className="flex flex-col justify-start text-left ml-2">
              <span>{lineSupport.line_id}</span>
            </div>
          </div>
        </a>

        <div className="flex items-center gap-2 flex-wrap justify-end">
          <BalanceBox>
            <div className="w-4 h-4">
              <img src="/asset/images/coin.png" alt="coin" className="" />
            </div>
            <span className="text-sm text-wrap">
              {moneyFormatWithDecimal(balance)}
            </span>
            <div className="cursor-pointer" onClick={handleRecheckBalance}>
              {isLoaded ? (
                <MoonLoader
                  color={"#fff"}
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <HiOutlineRefresh color="#fff" size={16} />
              )}
            </div>
          </BalanceBox>
          {user && (
            <ProfileBox onClick={() => setIsProfileModalOpen(true)}>
              <img src="asset/images/icon-user.png" alt="" />
              {user?.preferred_username}
            </ProfileBox>
          )}
        </div>
        <button className="min-w-12 max-w-16">
          <img className="w-full" src="/asset/images/th.png" alt="th" />
        </button>
      </div>
      {isDepositModalOpen &&
        createPortal(
          <Deposit bankAccount uploadSlip onClose={setIsDepositModalOpen} />,
          document.body
        )}

      {isWithdrawModalOpen &&
        createPortal(
          <Withdraw onClose={setIsWithdrawModalOpen} balance={balance} />,
          document.body
        )}

      {isHistoryModalOpen &&
        createPortal(
          <HistoryModal onClose={setIsHistoryModalOpen} />,
          document.body
        )}

      {isProfileModalOpen &&
        createPortal(
          <ProfileModal user={user} onClose={setIsProfileModalOpen} />,
          document.body
        )}
    </div>
  );
}

const BalanceBox = styled.div.attrs({
  className: "flex gap-2 items-center font-light h-8 justify-center px-1",
})(
  ({ theme }) => `
  border-radius: 0.5rem;
  min-width: 6rem;
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};
  box-shadow: var(--box-border-shadow);
`
);
const ProfileBox = styled.div.attrs({
  className: "font-bold text-sm text-right cursor-pointer",
})(
  ({ theme }) => `
  border-radius: 0.5rem;
  min-width: 6rem;
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};

  display: flex;
  padding: 0.5rem;
  align-items: center;
  background: #222324;
  img{
    max-width: 15px;
    margin-right: 0.25rem;
  }
`
);
