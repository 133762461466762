import { styled } from "styled-components";

export const TitleImgHead = styled.div.attrs({
  className: "relative",
})(
  ({ theme }) => `
    color: ${theme.colors.text};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`
);
