import MoonLoader from 'react-spinners/MoonLoader'
import { formatDateTime } from '../../utils/format'

export default function AffiliateTransactionTable({
  transactions = [],
  loading,
}) {
  return (
    <table className="w-full overflow-hidden mt-2">
      <thead className="bg-gradient-table py-4">
        <tr className="text-black">
          <th>#</th>
          <th className="py-2">วันสร้าง</th>
          <th>จำนวนเงิน</th>
          <th>สถานะ</th>
        </tr>
      </thead>
      <tbody className="">
        {loading ? (
          <tr>
            <td colSpan={4} className="py-2">
              <div className="flex justify-center">
                <MoonLoader color={"#fff"} size={18} className="text-center" />
              </div>
            </td>
          </tr>
        ) : transactions.length > 0 ? (
          transactions.map((transaction, index) => {
            return (
              <tr key={index} className="text-center font-bold">
                <td className="w-10 text-center">{index + 1}</td>
                <td className="py-2">
                  {formatDateTime(transaction.created_date)}
                </td>
                <td>{transaction.amount}</td>
                <td>{transaction.status}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={4} className="py-2 text-slate-200">
              ไม่มีข้อมูล
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
