import { HttpInterceptor } from "../utils/httpClient";
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2'

export async function getGames(game_type_id) {
  return await HttpInterceptor.get(`/games/v1/type/providers?game_type_id=${game_type_id}&agent_code=${process.env.REACT_APP_AGENT_CODE}`).then(async response => {
    let games = [];
    let requests = [];
    if (response.data) {
      response.data.map((obj) => {
        games.push({
          "code": obj.game_provider_code,
          "name": obj.game_provider_name_en
        })
        requests.push(
          HttpInterceptor.get(`/games/v1/games/${obj.game_provider_code}`)
        )
      });

      await HttpInterceptor.all(requests)
        .then(HttpInterceptor.spread((...responses) => {
          responses.map((obj, index) => {
            games[index] = { ...games[index], "id": obj.data[0].id, "imageUrl": `/asset/images/games/${obj.game_provider_code}.png` }
          });
        })).catch(errors => {
          // react on errors.
        })
    }

    return games;
  }).catch(error => {
    throw error
  });
}

export async function getSlotGames(provider_code) {
  return await HttpInterceptor.get(`/games/v1/games/${provider_code}`).then(async response => {

    let games = [];
    response.data.map((obj) => {
      games.push({
        "id": obj.id,
        "code": obj.game_provider_code,
        "name": obj.game_name_en,
        "imageUrl": obj.game_image_url,
      })
    });

    return games;
  }).catch(error => {
    throw error
  });
}

export async function launchGame(game_id) {
  const decode = jwtDecode(localStorage.getItem("token") || "");
  const body = {
    "game_id": Number(game_id),
    "user_id": decode.id,
    "agent_code": process.env.REACT_APP_AGENT_CODE
  }

  return await HttpInterceptor.post(`/games/v1/launches`, body).then(response => {
    return response.data.game_url
  }).catch(error => {
    Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${error.response.data.display_message}</span>`,
      showConfirmButton: false,
      timer: 3000
    });
    return error;
  });
}