import { formatDateTime } from "../../utils/format";
import { TableContainer, Theader, TheadLabel, TBody, TR, TbodyLabel } from "./Table"
import _ from "lodash"

export default function Table({ title, list }) {
    const setLabel = (value) => {
        let label;
        switch (value) {
            case 'success':
                label = { colors: '#05C973', text: 'สำเร็จ' }
                return label;
            case 'reject':
                label = { colors: '#BE2B43', text: 'ไม่สำเร็จ' }
                return label;
            default:
                return label = { colors: '#FFF', text: 'กำลังดำเนินการ' }
        }
    }

    return (
        <TableContainer>
            <Theader>
                <TheadLabel className="grow">วันที่{title}</TheadLabel>
                <TheadLabel className="w-1/4">จำนวนเงิน</TheadLabel>
                <TheadLabel className="w-1/4">สถานะ</TheadLabel>
            </Theader>
            <TBody>
                {!_.isEmpty(list) ?
                    list.map((obj, index) => {
                        return (
                            <TR key={index}>
                                <TbodyLabel className="grow">{formatDateTime(obj.transaction_time)}</TbodyLabel>
                                <TbodyLabel className="w-1/4">{obj.amount}</TbodyLabel>
                                <TbodyLabel $color={setLabel(obj.status).colors} className="w-1/4">{setLabel(obj.status).text}</TbodyLabel>
                            </TR>
                        );
                    }) : <div className="p-4">
                        <span className="text-white">ยังไม่มีรายการ{title}</span>
                    </div>
                }
            </TBody>
        </TableContainer>
    );
}