import { HttpInterceptor } from "../utils/httpClient";

export async function getPromotionsWithoutLogin() {
    return await HttpInterceptor.get(`/promotions/v1/public?agent_code=ATA1`).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}

export async function getPromotionsMember() {
  return await HttpInterceptor.get(`/promotions/v1/players`).then(response => {
      return response.data;
    }).catch(error => {
      throw error
    });
}