import { useContext, createContext, useState, useEffect } from 'react'
import { getLine } from '../api/dafaultService';

const SupportContext = createContext()

const SupportProvider = ({ children }) => {
  const [lineSupport, setLineSupport] = useState({});

  useEffect(() => {
    getImformationLine();
  }, []);

  const getImformationLine = async () => {
    const res = await getLine()
    setLineSupport({
      "line_id": res.line_id,
      "line_url": res.line_url
    })
  };

  useEffect(() => {

  }, [])

  return (
    <SupportContext.Provider value={{ lineSupport }}>
      {children}
    </SupportContext.Provider>
  )
}

export default SupportProvider

export const useSupport = () => {
  return useContext(SupportContext)
}
