import React from "react";
import Section from "../components/Section";
import { getGames } from "../api/gameService";

export default function Slot() {
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await getGames(3);
    setList(res);
  };

  return <Section text="สล๊อตออนไลน์" games={list} type={'slot'} />;
}
