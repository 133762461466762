import { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import {
  getConfigurationWheel,
  getPlayerTicketRemaining,
  getWheelDraw,
} from '../api/wheelService'
import { BtnPrimarySm2, ButtonSec } from "../components/Button";
import Swal from "sweetalert2";
import { useAuth } from "../hook/AuthProvider";

export default function Spin() {
  const { user } = useAuth();

  const [configurationsWheel, setConfigurationsWheel] = useState([]);
  const [ticket, setTicket] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cumulativeTurn, setCumulativeTurn] = useState(100);
  const [openResultModal, setOpenResultModal] = useState(false);
  const wheelRef = useRef(null);

  useEffect(() => {
    if (user) {
      handleGetConfigurationWheel();
      handleGetPlayerTicketRemaining();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleGetConfigurationWheel = async () => {
    try {
      const response = await getConfigurationWheel();
      setConfigurationsWheel(response.dataClient);
    } catch (error) {
      AlertError(error.response?.data?.display_message);
      return error;
    }
  };

  const handleGetPlayerTicketRemaining = async () => {
    try {
      const response = await getPlayerTicketRemaining();
      setTicket(response.data.ticket);
    } catch (error) {
      AlertError(error.response?.data?.display_message);
      return error;
    }
  };

  const handleGetWheelDraw = async () => {
    try {
      if (user && ticket && !loading) {
        setLoading(true);
        const response = await getWheelDraw({
          player_id: user.id,
          username: user.preferred_username.split(user.code.toLowerCase())[1],
        });
        await spining(response?.configuration_id);
      }
    } catch (error) {
      setLoading(false);
      AlertError(error.response?.data?.display_message);
      return error;
    }
  };

  const spining = async (configurationId) => {
    // find configuration wheel
    const configurationIndex =
      configurationsWheel.findIndex(
        (configuration) => configuration.id === configurationId
      ) ?? -1;
    if (configurationIndex === -1) return;

    // get ticket remaining
    await handleGetPlayerTicketRemaining();

    if (wheelRef.current) {
      // move spin to reward result
      const moveCount =
        configurationIndex > currentIndex
          ? configurationsWheel.length - (configurationIndex - currentIndex)
          : currentIndex - configurationIndex;
      const turn =
        cumulativeTurn + moveCount * (1 / configurationsWheel.length);
      wheelRef.current.style.transform = `rotate(${turn}turn)`;
      // add spin transition
      wheelRef.current.classList.add("turning");

      setCurrentIndex(configurationIndex);
      setCumulativeTurn(turn + 100);

      // show result modal
      const timeoutId = setTimeout(() => {
        setOpenResultModal(true);

        const closeModalTimeoutId = setTimeout(() => {
          setOpenResultModal(false);
          setLoading(false);

          clearTimeout(closeModalTimeoutId);
        }, 8000);

        clearTimeout(timeoutId);
      }, 6000);
    }
  };

  const AlertError = (message) => {
    return Swal.fire({
      position: "bottom",
      html: `<svg stroke="#871B1B" fill="#871B1B" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.001 10H13.001V15H11.001zM11 16H13V18H11z"></path><path d="M13.768,4.2C13.42,3.545,12.742,3.138,12,3.138s-1.42,0.407-1.768,1.063L2.894,18.064 c-0.331,0.626-0.311,1.361,0.054,1.968C3.313,20.638,3.953,21,4.661,21h14.678c0.708,0,1.349-0.362,1.714-0.968 c0.364-0.606,0.385-1.342,0.054-1.968L13.768,4.2z M4.661,19L12,5.137L19.344,19H4.661z"></path></svg><span>${
        message || "Something went wrong!"
      }</span>`,
      showConfirmButton: false,
      timer: 5000,
    });
  };

  return (
    <>
      <SpinContainer className="mx-auto mb-10">
        <WheelController onClick={handleGetWheelDraw}>
          <ControllerLabel>{ticket}</ControllerLabel>
          <SpinWrapper>
            <img src="/asset/images/spin.png" alt="spin" />
          </SpinWrapper>
        </WheelController>
        <ContentWrapper ref={wheelRef}>
          <SectorWrapper>
            {configurationsWheel.length > 0 &&
              configurationsWheel.map((_, index) => {
                return <Sector key={index} />;
              })}
          </SectorWrapper>
          <LabelWrapper>
            {configurationsWheel.length > 0 &&
              configurationsWheel.map((configuration) => {
                return (
                  <Label key={configuration.id}>
                    <img src="/asset/images/coin.png" alt="coin" />
                    <LabelText>{configuration.name}</LabelText>
                  </Label>
                );
              })}
          </LabelWrapper>
        </ContentWrapper>
      </SpinContainer>
      {user && (
        <div className="w-36 m-auto">
          <ButtonSec onClick={handleGetWheelDraw}>หมุนเลย</ButtonSec>
        </div>
      )}

      {openResultModal && (
        <ResultModal>
          <Overlay />
          <Modal>
            <TitleModal>ยินดีด้วย! คุณได้รับ</TitleModal>
            <TextResultModal>
              {configurationsWheel[currentIndex]?.name || ""}
            </TextResultModal>
            <div className="mt-2">
              <BtnPrimarySm2 onClick={() => setOpenResultModal(false)}>
                ปิด
              </BtnPrimarySm2>
            </div>
          </Modal>
        </ResultModal>
      )}
    </>
  );
}

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const WheelController = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  padding: 10px;
  overflow: hidden;
`;

const ControllerLabel = styled.h6`
  position: absolute;
  font-size: 2.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  color: #3f1a0c;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: 540px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 1.3rem;
  }
`;

const SpinWrapper = styled.div`
  background: rgba(255, 184, 79, 0.1490196078);
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    cursor: pointer;
    width: 70%;
  }

  @media screen and (max-width: 540px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 420px) {
    width: 120px;
    height: 120px;
  }
`;

const ContentWrapper = styled.div`
  width: calc(100% - 40px);
  max-width: 540px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  transition: all 0s;

  &.turning {
    transition: 5s cubic-bezier(0, 1.05, 0.13, 1);
  }

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
    border-radius: 50%;
  }
`;

const SectorWrapper = styled.div`
  position: absolute;
  background-image: url("/asset/images/bg-wheel.png");
  background-size: cover;
  background-position: center;
  inset: 0;
  border-radius: 50%;
  overflow: hidden;
`;

const Sector = styled.div`
  position: absolute;
  width: 50%;
  height: 50%;
  left: 50%;
  top: 50%;
  margin-top: -50%;
  margin-left: -50%;
  transform-origin: 100% 100%;
  font-size: 24px;
  display: flex;

  &:nth-child(1) {
    transform: rotate(0.1875turn) skew(45deg);
  }
  &:nth-child(2) {
    transform: rotate(-0.6875turn) skew(45deg);
  }
  &:nth-child(3) {
    transform: rotate(-0.5625turn) skew(45deg);
  }
  &:nth-child(4) {
    transform: rotate(-0.4375turn) skew(45deg);
  }
  &:nth-child(5) {
    transform: rotate(-0.3125turn) skew(45deg);
  }
  &:nth-child(6) {
    transform: rotate(-0.1875turn) skew(45deg);
  }
  &:nth-child(7) {
    transform: rotate(-0.0625turn) skew(45deg);
  }
  &:nth-child(8) {
    transform: rotate(0.0625turn) skew(45deg);
  }
`;

const LabelWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

const Label = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 50%;
  height: 50%;
  transform-origin: 50% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 2rem 1rem 2rem;

  @media screen and (max-width: 540px) {
    padding: 1.5rem 4rem 1rem 4rem;
  }

  @media screen and (max-width: 370px) {
    padding: 1.5rem 2.5rem 2rem 2.5rem;
  }

  & img {
    width: 50px;
    @media screen and (max-width: 600px) {
      width: 40px;
    }
    @media screen and (max-width: 420px) {
      width: 25px;
    }
  }

  &:nth-child(1) {
    transform: translateX(-50%) rotate(0turn);
  }
  &:nth-child(2) {
    transform: translateX(-50%) rotate(-0.875turn);
  }
  &:nth-child(3) {
    transform: translateX(-50%) rotate(-0.75turn);
  }
  &:nth-child(4) {
    transform: translateX(-50%) rotate(-0.625turn);
  }
  &:nth-child(5) {
    transform: translateX(-50%) rotate(-0.5turn);
  }
  &:nth-child(6) {
    transform: translateX(-50%) rotate(-0.375turn);
  }
  &:nth-child(7) {
    transform: translateX(-50%) rotate(-0.25turn);
  }
  &:nth-child(8) {
    transform: translateX(-50%) rotate(-0.125turn);
  }
`;

const LabelText = styled.h2`
  max-width: 80px;
  text-align: center;
  line-height: 26px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -1.2px;
  color: #3f1a0c;

  @media screen and (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 20px;
  }
  @media screen and (max-width: 370px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

const ResultModal = styled.div`
  max-height: 100vh;
`;

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99 !important;
`;

const Modal = styled.div(
  ({ theme }) => `
  position: fixed;
  z-index: 99 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${theme.colors.dark[200]};
  width: 380px;
  padding: 24px;
  text-align: center;
  border-radius: 22px;
`
);

const TitleModal = styled.h5(
  ({ theme }) => `
  color: ${theme.colors.white};
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 1px;
`
);

const TextResultModal = styled.h4(
  ({ theme }) => `
  color: ${theme.colors.primary};
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: 1px;
  padding: 1rem 0;
`
);
