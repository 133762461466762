import { styled } from 'styled-components'

const BaseButton = styled.button`
  padding: 0.25em 0.5em;
  border-radius: 0.5rem;
  white-space: nowrap;
  box-shadow: ${({ shadow }) =>
    shadow ? "2px 5px 10px 0 rgb(0 0 0 / 60%)" : "none"};

  ${({ shadow }) =>
    shadow &&
    `
    &:hover  {
      box-shadow: 0px 6px 10px 0 rgb(0 0 0 / 90%);
    }
  `}
`;

const ButtonPrimary = styled(BaseButton)(
  ({ theme }) => `
  color: ${theme.colors.text};
  background: ${theme.colors.dark[300]};
  border: none;
  width: 100%;
  transition: all .5s;
  box-shadow: var(--box-border-shadow);

  &:hover  {
    transition: all .5s;
    background: linear-gradient(-90deg, ${theme.colors.primaryDark}, ${theme.colors.secondary});
    color: ${theme.colors.black}
  }

  &.active {
    transition: all .5s;
    background: linear-gradient(90deg, ${theme.colors.primaryDark}, ${theme.colors.secondary});
    color: ${theme.colors.black}
  }
`
);

const ButtonSec = styled(BaseButton).attrs({
  className: "h-fit",
})(
  ({ theme }) => `
  background: linear-gradient(90deg, ${theme.colors.primaryDark}, ${theme.colors.secondary});
  color:  ${theme.colors.dark[300]};
  border: none;
  width: 100%;

  &:hover  {
    background: linear-gradient(-90deg, ${theme.colors.primaryDark}, ${theme.colors.secondary});
  }
`
);

const BtnPrimarySm = styled(ButtonPrimary)(
  ({ theme }) => `
  border: none;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.1s;
  height: 33px;
  width: 50%;
  padding: 0 1rem;

  &:disabled  {
    opacity: 0.4;
    background: ${theme.colors.gray};
    cursor: not-allowed;
  }
`
);
const BtnPrimaryXs = styled(ButtonPrimary)(
  ({ theme }) => `
  border: none;
  font-size: 12px;
  font-weight: 300;
  transition: all 0.1s;
  height: 33px;
  width: 50%;
  padding: 0 1rem;

  &:disabled  {
    opacity: 0.4;
    background: ${theme.colors.gray};
    cursor: not-allowed;
  }
`
);

const BtnPrimarySm2 = styled(ButtonSec)(({ theme }) => `
  font-size: 14px;
  font-weight: 300;
  transition: all 0.1s;
  height: 33px;
  width: auto;
  padding: 0 .8rem;

  &:disabled  {
    background: ${theme.colors.gray};
    cursor: not-allowed;
  }
`);

const BtnSecSm = styled(ButtonSec)`
  border: none;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.1s;
  height: 33px;
  padding: 0 1rem;
`;

export {
  ButtonPrimary,
  ButtonSec,
  BtnPrimarySm,
  BaseButton,
  BtnSecSm,
  BtnPrimarySm2,
  BtnPrimaryXs,
};