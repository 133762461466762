export const theme = {
  colors: {
    black: "#000",
    white: "#fff",
    gray: "#353535",
    red: "#BE2B43",
    light: {
      200: "#33D2D8",
      300: "#ffcf74",
      500: "#ffbf4e",
      800: "#d18500",
    },
    primary: "var(--primary)",
    secondary: "var(--secondary)",
    primaryDark: "var(--primary-dark)",
    blue: "#33D2D8",
    green: "#05C973",
    text: "#fff",
    dark: {
      200: "#323639",
      300: "#14171C",
      500: "#01050E",
      600: "#000308",
    },
    textLight: "#ffd27a",
    gradient: "linear-gradient(180deg, #ffcf74 0, #ffbf4e 50%, #d18500",
    borderGradient:
      "linear-gradient(45deg, #ffbf4e 0, #ffd27a 17%, #d18500 35%, #ffd27a 51%, #d18500 72%, #ffd27a 89%, #fff)",
    darkGradient: "linear-gradient(180deg, #75390c, #361800",
  },
};