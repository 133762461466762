import { HttpInterceptor } from "../utils/httpClient";

export async function getImages(image) {
    return await HttpInterceptor.get(`promotions/v1/public/${image}/images`,{
      responseType: 'blob'
    }).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}