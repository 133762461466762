import { useState } from 'react'
import { TableContainer, Theader, TheadLabel, TBody, TR, TbodyLabel } from "./Table"

export default function AffiliateCommissionTypeTable() {
  const affiliates = [
    { type: 'LOTTO', lv1: '', lv2: '' },
    { type: 'CASINO', lv1: '', lv2: '' },
    { type: 'SLOT', lv1: '', lv2: '' },
    { type: 'SPORT', lv1: '', lv2: '' },
  ]
  const downlineAffiates = [
    { gameProvider: 'HDG', type: 'LOTTO', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SA', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'AE', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'BG', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'AG', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'AB', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'WM', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'EVO', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'YB', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'WE', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'PPL', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'VIVO', type: 'CASINO', lv1: '0.5%', lv2: '0.05%' },
    { gameProvider: 'KA', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'JDB', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'JILI', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'MG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'HB', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PP', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'CQ9', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'JK', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'YGG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'UPG', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'RELAX', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'MAR', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'FC', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'NLM', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SKW', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SPX', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'PGS', type: 'SLOT', lv1: '0.3%', lv2: '0.1%' },
    { gameProvider: 'SBOT', type: 'SPORT', lv1: '0.25%', lv2: '0.1%' },
    { gameProvider: 'BTI', type: 'SPORT', lv1: '0.25%', lv2: '0.1%' },
  ]

  const [expands, setExpands] = useState([])

  const handleExpand = (type, isExpand) => {
    if (isExpand) {
      const removed = expands.filter((expand) => expand !== type)
      setExpands(removed)
    } else {
      setExpands((values) => [...values, type])
    }
  }

  return (
    <TableContainer>
      <Theader>
        <TheadLabel className="w-10"></TheadLabel>
        <TheadLabel className="w-2/4">ประเภท</TheadLabel>
        <TheadLabel className="w-2/4">ดาวน์ไลน์ LV1</TheadLabel>
        <TheadLabel className="w-2/4">ดาวน์ไลน์ LV2</TheadLabel>
      </Theader>
      <TBody className="">
        {affiliates.length > 0 &&
          affiliates.map((affiliate, index) => {
            const isExpand = expands.includes(affiliate.type);
            return (
              <>
                <TR key={index}>
                  <TbodyLabel className="w-10">
                    {affiliate.type !== "LOTTO" && (
                      <button
                        onClick={() => handleExpand(affiliate.type, isExpand)}
                        className="bg-[rgba(255,255,255,0.8)] text-black w-4 h-4 rounded-sm inline-flex justify-center items-center outline-none">
                        <span className="pb-1">{isExpand ? "-" : "+"}</span>
                      </button>
                    )}
                  </TbodyLabel>
                  <TbodyLabel className="w-2/4 text-primary">
                    {affiliate.type}
                  </TbodyLabel>
                  <TbodyLabel className="w-2/4">{affiliate.lv1}</TbodyLabel>
                  <TbodyLabel className="w-2/4">{affiliate.lv2}</TbodyLabel>
                </TR>
                {isExpand && (
                  <TR className="flex flex-col pl-10">
                    <Theader>
                      <TheadLabel className="w-1/3">เกม</TheadLabel>
                      <TheadLabel className="w-1/3">ดาวน์ไลน์ LV1</TheadLabel>
                      <TheadLabel className="w-1/3">ดาวน์ไลน์ LV2</TheadLabel>
                    </Theader>
                    <TBody>
                      {downlineAffiates.length > 0 &&
                        downlineAffiates
                          .filter((data) => data.type === affiliate.type)
                          .map((downlineAffiate) => {
                            return (
                              <TR key={downlineAffiate.gameProvider}>
                                <TbodyLabel className="w-1/3">
                                  {downlineAffiate.gameProvider}
                                </TbodyLabel>
                                <TbodyLabel className="w-1/3">
                                  {downlineAffiate.lv1}
                                </TbodyLabel>
                                <TbodyLabel className="w-1/3">
                                  {downlineAffiate.lv2}
                                </TbodyLabel>
                              </TR>
                            );
                          })}
                    </TBody>
                  </TR>
                )}
              </>
            )
          })}
      </TBody>
    </TableContainer>
  );
}
