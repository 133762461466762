import React from "react";
import {
    ModalContainer,
    ModalContentContainer,
    ModalClose,
} from "./Model";
import { HiOutlineX } from "react-icons/hi";
import RegisterForm from "../Form/RegisterForm"

export default function RegisterModal({ onClose }) {
    return (
      <ModalContainer
        onClick={(e) => {
          if (e.target.id === "modal-container") {
            onClose(false);
          }
        }}>
        <ModalContentContainer className="text-center">
          <ModalClose onClick={() => onClose(false)}>
            <HiOutlineX className="close-icon" size={24} color="#f00" />
          </ModalClose>
          <h2 className="mb-5 text-white text-center font-bold text-xl">
            สมัครสมาชิก
          </h2>
          <RegisterForm />
        </ModalContentContainer>
      </ModalContainer>
    );
}