import { ButtonPrimary } from "./Button";
import { styled } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSupport } from "../hook/SupportProvider";

export default function MobileNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { lineSupport } = useSupport();

  const menu = [
    {
      name: "หน้าหลัก",
      name_en: "Home",
      pathname: "/",
    },
    {
      name: "กีฬา",
      name_en: "Sport",
      pathname: "/sport",
    },
    {
      name: "คาสิโน",
      name_en: "Casino",
      pathname: "/casino",
    },
    {
      name: "สล็อต",
      name_en: "Slot",
      pathname: "/slot",
    },
    {
      name: "โปรโมชั่น",
      name_en: "Promotion",
      pathname: "/promotion",
    },
    {
      name: "วงล้อนำโชค",
      name_en: "Lucky Wheel",
      pathname: "/spin",
    },
    {
      name: "หารายได้",
      name_en: "Affiliate",
      pathname: "/affiliate",
    },
  ];

  return (
    <Box>
      {menu.map((obj, index) => {
        return (
          <ButtonPrimary
            className={`border-2 mx-1 max-w-36 ${
              location.pathname === obj.pathname && "active"
            }`}
            key={index}
            onClick={() => navigate(obj.pathname)}>
            <div className="flex flex-row content-center items-center">
              <img
                src={`/asset/images/icon-menu/${
                  obj.pathname === "/" ? "home" : obj.pathname
                }.png`}
                alt="icon menu"
                className="w-6 h-6 lg:w-8 lg:h-8"
              />
              <div
                className="flex flex-col justify-start text-left ml-1 lg:ml-2"
                style={{ fontSize: "12px" }}>
                <span className="">{obj.name}</span>
                <MenuEngLabel>{obj.name_en.toUpperCase()}</MenuEngLabel>
              </div>
            </div>
          </ButtonPrimary>
        );
      })}
    </Box>
  );
}

const Box = styled.div.attrs({
  className: "w-full hidden md:flex p-2 justify-center",
})(
  ({ theme }) => `
  background: linear-gradient(0deg, transparent, ${theme.colors.dark[600]});
`
);
const MenuEngLabel = styled.div.attrs({
  className: "opacity-50",
})`
  @media only screen and (max-width: 1023px) {
    font-size: 10px;
  }
`;
