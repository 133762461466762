import { HttpInterceptor } from '../utils/httpClient'

export async function receivePromotions(playerId) {
  return await HttpInterceptor.put(`/players/promotion/${playerId}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export async function playerReportAffiliate(params) {
  return await HttpInterceptor.get('/reports/v1/affiliate', {
    params: params || {},
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export async function getBalance() {
  return await HttpInterceptor.get(`/players/balance`).then(response => {
      return response.data;
    }).catch(error => {
      throw error
    });
}

export async function getPlayerBankAccount() {
  return await HttpInterceptor.get(`/players/v1/bankaccount`).then(response => {
      return response.data;
    }).catch(error => {
      throw error
    });
}
