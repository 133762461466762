import { styled } from "styled-components";
import Footer from "./components/Footer/Footer";

export default function Main({ children }) {
  return (
    <MainContainer>
      <div className="flex-1">{children}</div>
      <Footer />
    </MainContainer>
  );
}

const MainContainer = styled.div.attrs({
  className: "flex flex-col flex-1 overflow-scroll pt-8 pb-24 md:pb-8",
})`
  @media only screen and (max-width: 425px) {
    padding-left: 70px;
  }
`;