import React from 'react'
import { ModalContainer, ModalContentContainer, ModalClose } from './Model'
import { HiOutlineX } from 'react-icons/hi'
import { Label, Input, AlertRequired } from '../Form/Form'
import { useForm } from 'react-hook-form'
import { BtnPrimarySm2 } from '../Button'
import { changePassword } from '../../api/authService'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default function ChangePassword({ onClose }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: "onBlur"
  })
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const submit = async () => {
    if(_.isEmpty(errors)){
      const res = await changePassword(getValues('password'))
      if (!_.isUndefined(res)) {
        onClose(false)
        Swal.fire({
          position: 'bottom',
          html: `<svg stroke="#05C973" fill="#05C973" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" /><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /></svg>&nbsp;<span className="text-sm">เปลี่ยนรหัสผ่านสำเร็จ</span>`,
          showConfirmButton: false,
          timer: 3000,
          width: '300px',
        })
      }
    }
  }

  return (
    <ModalContainer
      onClick={(e) => {
        if (e.target.id === "modal-container") {
          onClose(false);
        }
      }}>
      <ModalContentContainer className="text-center">
        <ModalClose onClick={() => onClose(false)}>
          <HiOutlineX className="close-icon" size={24} color="#f00" />
        </ModalClose>
        <h2 className="mb-10 text-white text-center font-bold text-xl">
          เปลี่ยนรหัส
        </h2>
        <Label>รหัสผ่าน</Label>
        <div className="relative">
          <Input
            type={showPassword ? "text" : "password"}
            {...register("password", {
              required: "กรุณาระบุ",
              minLength: {
                value: 6,
                message: "ระบุรหัสผ่านอย่างน้อย 6 หลัก",
              },
            })}
            aria-invalid={errors.password ? "true" : "false"}
          />
          <AlertRequired>{errors.password?.message}</AlertRequired>
          <div
            className="absolute top-1.5 right-2 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <HiOutlineEyeOff color="#fff" size={18} />
            ) : (
              <HiOutlineEye color="#fff" size={18} />
            )}
          </div>
        </div>

        <Label>ยืนยันรหัสผ่าน</Label>
        <div className="relative">
          <Input
            type={showConfirmPassword ? "text" : "password"}
            {...register("confirmPass", {
              required: "กรุณาระบุ",
              minLength: {
                value: 6,
                message: "ระบุรหัสผ่านอย่างน้อย 6 หลัก",
              },
              validate: (val) => {
                if (watch("password") !== val) {
                  return "รหัสผ่านไม่ตรงกัน";
                }
              },
            })}
            aria-invalid={errors.confirmPass ? "true" : "false"}
          />
          <AlertRequired>{errors.confirmPass?.message}</AlertRequired>
          <div
            className="absolute top-1.5 right-2 cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            {showConfirmPassword ? (
              <HiOutlineEyeOff color="#fff" size={18} />
            ) : (
              <HiOutlineEye color="#fff" size={18} />
            )}
          </div>
        </div>
        <BtnPrimarySm2
          className="my-4 text-lg min-w-full"
          onClick={() => handleSubmit(submit())}>
          ยืนยัน
        </BtnPrimarySm2>
      </ModalContentContainer>
    </ModalContainer>
  );
}
