import React from 'react';
import { HiChevronDoubleRight } from "react-icons/hi";
import styled from 'styled-components';

export default function Stepper({ steps, currentStep, numberOfSteps }) {
  const isCurrentStep = (index) => (currentStep == index ? true : false);
  const activeColor = (index) => (currentStep >= index ? true : false);
  const isFinalStep = (index) => index === numberOfSteps - 1;

  return (
    <StepperBox>
      {steps.map((obj, index) => (
        <React.Fragment key={index}>
          <StepperWrap
            $isAcitve={activeColor(index)}
            $isCurrentStep={isCurrentStep(index)}>
            {/* <StepperItem $isAcitve={activeColor(index)}>{index + 1}</StepperItem> */}
            <StepperName $isAcitve={activeColor(index)}>{obj}</StepperName>
          </StepperWrap>
          {isFinalStep(index) ? null : <HiChevronDoubleRight color="#fff" />}
        </React.Fragment>
      ))}
    </StepperBox>
  );
}

const StepperBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    display: none;
  }
`;

const StepperWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex: 1;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid;
  position: relative;
  border-color: ${(props) =>
    !!props.$isAcitve ? props.theme.colors.primary : props.theme.colors.gray};
  &:after {
    content: ${(props) => (!!props.$isCurrentStep ? "" : "unset")} "";
    width: 10px;
    height: 10px;
    right: -6px;
    bottom: -6px;
    z-index: 1;
    background: ${(props) => props.theme.colors.primary};
    position: absolute;
    border-radius: 40px;
  }
`;

const StepperItem = styled.div`
  border: 2px solid
    ${(props) => (!!props.$isAcitve ? `transparent` : props.theme.colors.white)};
  background: ${(props) =>
    !!props.$isAcitve
      ? `linear-gradient(90deg, ${props.theme.colors.blue}, ${props.theme.colors.green})`
      : `transparent`};
  color: ${(props) => (!!props.$isAcitve ? `black` : props.theme.colors.white)};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  line-height: 44px;
  font-size: 20px;
  margin: auto;
`;

const StepperName = styled.div`
  color: ${(props) =>
    !!props.$isAcitve ? props.theme.colors.primary : props.theme.colors.gray};
  font-size: 12px;
  padding-top: 8px;
`;
