import { HttpInterceptor } from "../utils/httpClient";

export async function getListWithdraw() {
    return await HttpInterceptor.get(`/players/getWithdrawTxn`).then(response => {
        return response.data;
      }).catch(error => {
        throw error
      });
}

export async function getStatus() {
  return await HttpInterceptor.get(`/players/withdraw/status`).then(response => {
      return response.data;
    }).catch(error => {
      throw error
    });
}


export async function withdraw(amount) {
  const body = {
    "withdrawAmount": amount
  }
  
  return await HttpInterceptor.post(`/players/withdraw`, body).then(response => {
      return response;
    }).catch(error => {
      throw error
    });
}
