import * as React from "react";
import { useForm } from "react-hook-form";
import { Label, Input, Dropdrown, AlertRequired } from "../Form/Form";
import { ButtonSec } from "../Button";
import Stepper from "../Stepper";
import { getSellers, getBanks } from "../../api/authService";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import styled from "styled-components";
import { formatAccountLength } from "../../utils/format";
import { signup } from "../../api/authService";
import { useLocation } from "react-router-dom";
import _ from "lodash";

export default function RegisterForm() {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onBlur",
  });

  const [sellers, setSellers] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [bankAccout, setBankAccout] = React.useState();
  const [accountLength, setAccountLength] = React.useState("10");
  const [isShowSellers, setIsShowSellers] = React.useState(true);
  const [isDisable, setIsDisable] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [bankCheck, setBankCheck] = React.useState(false);

  const [currentStep, setCurrentStep] = React.useState(0);
  const NUMBER_OF_STEPS = 2;
  const steps = ["ข้อมูลส่วนตัว", "ข้อมูลบัญชี"];

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      name === "sellerRef" && value.sellerRef !== ""
        ? setIsDisable(true)
        : setIsDisable(false);
      value.sellerCode !== ""
        ? setIsShowSellers(false)
        : setIsShowSellers(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const goToNextStep = () =>
    setCurrentStep((prev) => (prev === NUMBER_OF_STEPS - 1 ? prev : prev + 1));
  const goToPreviousStep = () =>
    setCurrentStep((prev) => (prev <= 0 ? prev : prev - 1));

  React.useEffect(() => {
    const pathname = location.pathname.split("/");
    if (pathname[2] !== undefined) {
      setValue("sellerCode", pathname[2]);
      setIsDisable(true);
    }
    getList();
  }, []);

  const getList = async () => {
    const listSeller = await getSellers();
    setSellers(listSeller);

    const listBank = await getBanks();
    setBanks(listBank);
  };

  const selectBank = (value, length) => {
    setBankCheck(false);
    setValue("accountNo", value === "TRUE" ? getValues("phone") : "");
    setBankAccout(value);
    setAccountLength(length);
  };

  const submit = async (data) => {
    if (bankAccout === undefined) {
      setBankCheck(true);
    } else {
      let seller_code =
        data.sellerRef === "" ? data.sellerCode : data.sellerRef;

      let res = await signup(
        data.phone,
        data.password,
        bankAccout,
        data.accountNo,
        data.accountName,
        seller_code
      );

      if (res === "approve") window.location = "/";
    }
  };

  return (
    <div>
      <Stepper
        steps={steps}
        currentStep={currentStep}
        numberOfSteps={NUMBER_OF_STEPS}
      />
      <br />
      {currentStep === 0 && (
        <>
          <Label>รหัสผู้แนะนำ (ถ้ามี)</Label>
          <Input
            disabled={isDisable}
            {...register("sellerCode")}
            aria-invalid={errors.sellerCode ? "true" : "false"}
            placeholder="ผู้ชักชวน"
          />
          {isShowSellers && (
            <>
              <Label>รู้จักเราจากที่ไหน</Label>
              <Dropdrown {...register("sellerRef")}>
                <option value={""}>ไม่ระบุ</option>
                {sellers &&
                  sellers.map((obj, index) => {
                    return (
                      <option key={index} value={obj.seller_code}>
                        {obj.display_name}
                      </option>
                    );
                  })}
              </Dropdrown>
            </>
          )}

          <Label>เบอร์โทร์ศัพท์</Label>
          <Input
            {...register("phone", {
              required: "กรุณาระบุ",
              pattern:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            })}
            aria-invalid={errors.phone ? "true" : "false"}
          />
          <AlertRequired>{errors.phone?.message}</AlertRequired>

          <Label>รหัสผ่าน</Label>
          <div className="relative">
            <Input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "กรุณาระบุ",
                minLength: {
                  value: 6,
                  message: "ระบุรหัสผ่านอย่างน้อย 6 หลัก",
                },
              })}
              aria-invalid={errors.password ? "true" : "false"}
            />
            <AlertRequired>{errors.password?.message}</AlertRequired>
            <div
              className="absolute top-1.5 right-2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <HiOutlineEyeOff color="#fff" size={18} />
              ) : (
                <HiOutlineEye color="#fff" size={18} />
              )}
            </div>
          </div>

          <Label>ยืนยันรหัสผ่าน</Label>
          <div className="relative">
            <Input
              type={showPassword ? "text" : "password"}
              {...register("confirmPass", {
                required: "กรุณาระบุ",
                minLength: {
                  value: 6,
                  message: "ระบุรหัสผ่านอย่างน้อย 6 หลัก",
                },
                validate: (val) => {
                  if (watch("password") !== val) {
                    return "รหัสผ่านไม่ตรงกัน";
                  }
                },
              })}
              aria-invalid={errors.confirmPass ? "true" : "false"}
            />
            <AlertRequired>{errors.confirmPass?.message}</AlertRequired>
            <div
              className="absolute top-1.5 right-2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <HiOutlineEyeOff color="#fff" size={18} />
              ) : (
                <HiOutlineEye color="#fff" size={18} />
              )}
            </div>
          </div>
          <ButtonSec
            className="mb-4 mt-6 text-lg"
            onClick={handleSubmit(goToNextStep)}>
            ถัดไป
          </ButtonSec>
        </>
      )}
      {currentStep > 0 && (
        <>
          {bankCheck && <AlertRequired>กรุณาเลือกธนาคาร</AlertRequired>}
          <div className="grid grid-cols-4 gap-2 h-48 overflow-scroll">
            {banks &&
              banks.map((obj, index) => {
                return (
                  <BankAccount
                    key={index}
                    onClick={() =>
                      selectBank(obj.bank_abbrev_en, obj.account_length)
                    }
                    className={bankAccout === obj.bank_abbrev_en && "active"}>
                    <img
                      src={`/asset/images/banks/${obj.bank_abbrev_en}.png`}
                      alt={obj.bank_name}
                      className="m-auto mb-2 w-10 rounded"
                    />
                    {obj.bank_abbrev_en}
                  </BankAccount>
                );
              })}
          </div>
          <Label>เลขบัญชีธนาคาร</Label>
          <Input
            type="text"
            disabled={_.isEqual(bankAccout, "TRUE")}
            {...register("accountNo", {
              required: "กรุณาระบุ เลขที่บัญชี",
              pattern: {
                value: /^[0-9]*$/,
                message: "เลขที่บัญชีต้องเป็นตัวเลขเท่านั้น",
              },
              validate: (value) => value > 0,
            })}
            maxLength={formatAccountLength(accountLength)}
            aria-invalid={errors.accountNo ? "true" : "false"}
          />
          <AlertRequired>{errors.accountNo?.message}</AlertRequired>

          <Label>ชื่อบัญชี</Label>
          <Input
            {...register("accountName", { required: "กรุณาระบุ ชื่อบัญชี" })}
            aria-invalid={errors.accountName ? "true" : "false"}
          />
          <AlertRequired>{errors.accountName?.message}</AlertRequired>

          <ButtonSec
            className="mb-4 mt-6 text-lg"
            onClick={handleSubmit(submit)}>
            ยืนยัน
          </ButtonSec>
          <button onClick={goToPreviousStep} className="text-white">
            กลับ
          </button>
        </>
      )}
    </div>
  );
}

const BankAccount = styled.div.attrs({
  className: "p-1 text-white border",
})(
  ({ theme }) => `
    font-size: 12px;
    text-align: center;
    border-color: ${theme.colors.gray};
    cursor: pointer;

    &:hover  {
      border: 1px solid ${theme.colors.primary};
      background:rgba(5,201,115,0.31);
    }

    &.active {
      border: 1px solid ${theme.colors.primary};
      background:rgba(5,201,115,0.31);
    }
`
);
