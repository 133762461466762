import React from "react";
import Section from "../components/Section";
import { getGames } from "../api/gameService";
import { styled } from "styled-components";
import { createPortal } from "react-dom";
import { ButtonSec } from "../components/Button";
import RegisterModal from "../components/Model/Register";

export default function Landing() {
  const [slot, setSlot] = React.useState([]);
  const [isRegisterModalOpen, setRegisterModalOpen] = React.useState(false);

  React.useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let gameSlot = await getGames(3);
    setSlot(gameSlot);
  };

  return (
    <>
      {/* <ContentRegister>
        <img src="/asset/images/landing-register.png" alt="spin" />
        <ButtonSec
          shadow="true"
          className="text-lg me-2 w-44 block max-w-36"
          onClick={() => setRegisterModalOpen(true)}>
          สมัครสมาชิก
        </ButtonSec>
      </ContentRegister> */}
      <Section
        text="สล๊อตออนไลน์"
        games={slot}
        description={"บริการเดิมพันเกมสล็อตออนไลน์ยอดนิยม"}
        type={"slot"}
      />
      {isRegisterModalOpen &&
        createPortal(
          <RegisterModal onClose={setRegisterModalOpen} />,
          document.body
        )}
    </>
  );
}
const ContentRegister = styled.div.attrs({
  className: "max-w-sm m-auto relative p-1",
})`
  img {
  }
  button {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    margin: auto;
  }
`;
