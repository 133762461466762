
import { styled } from 'styled-components'

const TableContainer = styled.div.attrs({
  className: "flex flex-col mt-2 overflow-hidden max-h-64",
})``;

const Theader = styled.div.attrs({
  className: "w-full h-10 p-2 flex flex-row justify-between",
})(
  ({ theme }) => `
background: linear-gradient(90deg, ${theme.colors.primaryDark}, ${theme.colors.secondary});
color: ${theme.colors.black}
`
);

const TheadLabel = styled.div.attrs({
  className: "text-sm ",
})``;

const TBody = styled.div.attrs({
    className: "overflow-scroll w-full p-1",
})``;

const TR = styled.div.attrs({
  className: "text-white text-xs font-light flex flex-row justify-between p-2",
})(
  ({ theme }) => `
&:nth-child(even) {
        background-color: ${theme.colors.dark[300]};
      }
`
);

const TbodyLabel = styled.div.attrs({
    className: "text-sm",
})`
    color: ${props => props.$color};
`;

export { TableContainer, Theader, TheadLabel, TBody, TR, TbodyLabel }