import { useContext, createContext, useState, useEffect } from 'react'
import { login } from '../api/authService'
import { jwtDecode } from 'jwt-decode'
import _ from 'lodash'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  let decode
  if (
    !_.isNil(localStorage.getItem('token')) &&
    !_.startsWith(localStorage.getItem('token'), 'undefined')
  ) {
    decode = jwtDecode(localStorage.getItem('token'))
  }
  const [user, setUser] = useState(
    decode
      ? {
          id: decode.id,
          name: decode.name,
          preferred_username: decode.preferred_username,
          code: decode.code,
        }
      : null
  )

  const [token, setToken] = useState(localStorage.getItem('token') || '')

  useEffect(() => {}, [user])

  const loginAction = async (username, password) => {
    const response = await login(username, password)
    if (response.status === 200) {
      const decode = jwtDecode(response.data.access_token)
      setUser({
        id: decode.id,
        name: decode.name,
        preferred_username: decode.preferred_username,
        code: decode.code,
      })
      setToken(response.data.access_token)
    }
    return response
  }

  const logOut = () => {
    setToken('')
    setUser(null)
    localStorage.clear()
    window.location.reload()
  }

  return (
    <AuthContext.Provider value={{ user, token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}
